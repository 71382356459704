<script>
export default {
  name: "animated_fire"
}
</script>

<template>
<svg width="27" height="37" viewBox="0 0 27 37" fill="none" xmlns="http://www.w3.org/2000/svg" class="flame">
  <path d="M13.5456 3.86397C14.8873 1.79466 14.2249 2.93755 16.1216 0C16.0788 0.614753 15.8734 2.41498 16.1216 3.54197C16.3699 4.66897 16.6783 5.47396 16.9266 6.11795L16.9366 6.14394C17.1832 6.78359 17.491 7.58205 18.0536 8.69394C18.6239 9.82093 19.0349 10.3621 19.6636 11.4309L21.5956 14.3289L23.3666 17.0659L24.6546 18.9979L25.9425 21.0908C26.4633 21.9402 26.5128 22.0568 26.8348 23.1838C27.1568 24.3108 26.9085 25.9208 26.9085 25.9208C26.9085 25.9208 26.8348 27.2088 26.4255 28.0138C26.0163 28.8188 25.9425 29.1408 25.2986 29.9458C24.6546 30.7508 24.4063 31.0728 23.3666 32.0388C22.3268 33.0048 21.6693 33.4878 20.4686 34.1317C19.2679 34.7757 16.1216 35.7417 16.1216 35.7417C17.1413 35.0977 19.2772 33.3268 19.6636 31.3948C20.1466 28.9798 19.6636 27.3959 19.6636 26.8868C19.6636 26.4038 18.7849 23.9888 18.3756 23.3448C18.2211 23.1018 17.5505 21.4128 16.8529 20.4468L14.1159 16.5829C13.5256 15.6169 12.0094 13.6849 11.2917 11.7529C10.5739 9.82093 11.1856 8.39396 11.2917 8.04994C11.5399 7.24495 12.2524 5.85854 13.5456 3.86397Z" fill="url(#paint0_linear_78_368)"/>
  <path d="M7.89065 15.6824C8.23467 14.4453 8.0769 15.1227 8.56203 13.3672C8.60575 13.7082 8.70093 14.7129 8.9151 15.3138C9.12927 15.9147 9.33603 16.3342 9.50206 16.6697L9.50876 16.6832C9.67365 17.0164 9.87949 17.4323 10.2211 18.0016C10.5673 18.5785 10.7898 18.8453 11.1543 19.386L12.2356 20.835L13.2348 22.2075L13.9556 23.1734L14.6925 24.2279C14.9908 24.656 15.0227 24.7164 15.2671 25.3118C15.5116 25.9073 15.5702 26.8106 15.5702 26.8106C15.5702 26.8106 15.6684 27.5239 15.5808 27.9968C15.4931 28.4696 15.495 28.6521 15.3111 29.1424C15.1272 29.6327 15.0575 29.8282 14.7273 30.4364C14.3972 31.0447 14.1756 31.359 13.7473 31.8023C13.3191 32.2456 12.1249 33.0105 12.1249 33.0105C12.4789 32.5807 13.1784 31.4485 13.1443 30.358C13.1017 28.9948 12.7457 28.1602 12.695 27.8804C12.6468 27.615 12.0457 26.3531 11.8136 26.0296C11.726 25.9075 11.2826 25.0292 10.9002 24.5502L9.39242 22.6302C9.054 22.1432 8.23954 21.1942 7.75257 20.1858C7.26561 19.1774 7.37422 18.3476 7.38344 18.1507C7.40503 17.6898 7.55906 16.8748 7.89065 15.6824Z" fill="url(#paint1_linear_78_368)"/>
  <path d="M10.3768 33.4141C10.3386 34.4952 10.3344 33.9092 10.2827 35.4429C10.1798 35.1721 9.90393 34.3678 9.61015 33.9178C9.31637 33.4677 9.06439 33.1648 8.86232 32.9227L8.85417 32.9129C8.65347 32.6724 8.40295 32.3722 8.01102 31.9732C7.61377 31.5687 7.37895 31.394 6.97394 31.0228L5.80282 30.0488L4.71401 29.1213L3.93326 28.4719L3.12194 27.7532C2.79332 27.4613 2.75526 27.4181 2.43778 26.9785C2.12031 26.5388 1.89437 25.8104 1.89437 25.8104C1.89437 25.8104 1.67348 25.2454 1.65214 24.8407C1.63079 24.4361 1.59334 24.287 1.64742 23.8492C1.7015 23.4113 1.72014 23.2374 1.87081 22.6742C2.02149 22.1109 2.14106 21.8097 2.40462 21.3623C2.66817 20.9148 3.49576 20.053 3.49576 20.053C3.29039 20.4748 2.94032 21.5401 3.18302 22.4267C3.48639 23.535 3.94236 24.1485 4.03903 24.3678C4.13075 24.5757 4.8717 25.4911 5.1255 25.7104C5.2213 25.7931 5.75749 26.4253 6.16511 26.7424L7.77834 28.0183C8.15147 28.3506 9.00555 28.9676 9.60305 29.6978C10.2005 30.428 10.275 31.1291 10.3062 31.2922C10.3793 31.674 10.4136 32.372 10.3768 33.4141Z" fill="url(#paint2_linear_78_368)"/>
  <defs>
    <linearGradient id="paint0_linear_78_368" x1="18.9738" y1="0" x2="18.9738" y2="35.7417" gradientUnits="userSpaceOnUse">
      <stop stop-color="#DC2B12"/>
      <stop offset="1" stop-color="#E68A48"/>
    </linearGradient>
    <linearGradient id="paint1_linear_78_368" x1="9.73187" y1="13.155" x2="13.2948" y2="32.7983" gradientUnits="userSpaceOnUse">
      <stop stop-color="#DC2B12"/>
      <stop offset="1" stop-color="#EF6908"/>
    </linearGradient>
    <linearGradient id="paint2_linear_78_368" x1="9.3662" y1="35.8471" x2="2.57923" y2="20.4572" gradientUnits="userSpaceOnUse">
      <stop stop-color="#EF6908"/>
      <stop offset="1" stop-color="#DC2B12"/>
    </linearGradient>
  </defs>
</svg>
</template>

<style scoped lang="scss">
  @keyframes flash {
    0%, 100% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0.2;
      transform: scale(1.2);
    }
  }

  .flame {
    animation: flash 1.5s infinite ease-in-out;
  }
</style>