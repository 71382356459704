<script>
export default {
  name: "logotype_footer",
  data() {
    return {
      imageSrc: require('@/assets/images/logo_footer.png') // путь к картинке
    };
  }
}
</script>

<template>
  <img :src="imageSrc" alt="Logotype" class="logo-image">
</template>

<style scoped lang="scss">
.logo-image{
  width: 380px;
  height: 120px;
}
</style>