<script>
export default {
  name: "copyright_footer"
}
</script>

<template>
  <div class="copyright_footer">
    <div class="container">
      <p class="text vendor">© 2016-2024 Kotlov City</p>
      <p class="text developer">Разработано: Elisha Krauchuk</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/base";
.copyright_footer{
  width: 100%;
  background-color: $lggrey;
}
.container{
  display: flex;
  justify-content: space-between;
  color: $text_color;
}
.text{
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 20px 0;
}
</style>