<script>
export default {
  name: "product_card",
  props: ["product_data"],
  data() {
    return {
      currentMediaIndex: 0, // Индекс текущего медиа
      status_mapping: {
        "available": "green",
        "temporary_unavailable": "orange",
        "unavailable": "red",
      },
    };
  },

  computed: {
    // Текущее отображаемое медиа
    currentMedia() {
      return this.product_data.media_files[this.currentMediaIndex];
    },
  },

  methods: {

    // Переключение на следующее медиа
    nextMedia() {
      if (this.currentMediaIndex < this.product_data.media_files.length - 1) {
        this.currentMediaIndex++;
      } else {
        this.currentMediaIndex = 0; // Вернуться к первому
      }
    },
    // Переключение на предыдущее медиа
    prevMedia() {
      if (this.currentMediaIndex > 0) {
        this.currentMediaIndex--;
      } else {
        this.currentMediaIndex = this.product_data.media_files.length - 1; // Вернуться к последнему
      }
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="media_area">

      <div class="media" v-if="currentMedia" @click="goto">
        <img v-if="currentMedia.media_type === 'image'" :src="currentMedia.file" alt="Product Image">
        <video v-else :src="currentMedia.file" controls autoplay muted></video>
      </div>

      <!-- Контроллеры переключения -->
      <button @click="prevMedia" class="media-control left-control" v-if="product_data.media_files.length > 1">←
      </button>
      <button @click="nextMedia" class="media-control right-control" v-if="product_data.media_files.length > 1">→
      </button>
    </div>

    <div>
      <p class="title">{{ product_data.name }}</p>
      <p class="description">{{ product_data.short_description }}</p>
      <div class="status" :style="{'color': status_mapping[product_data.status.value]}">{{
          product_data.status.label
        }}
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "@/assets/base";

.card {
  max-width: 300px;
  background-color: $extralightgrey_color;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid $lggrey;
  position: relative;
}

.media_area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;

  .media {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    img, video {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover; /* Убедиться, что медиа не выходит за рамки */
    }
  }
}

.title {
  font-weight: bold;
  font-size: 26px;
  padding: 10px;
  color: $dg2;
  cursor: pointer;
}

.description {
  padding: 0 10px;
  color: $lggrey;
  cursor: pointer;
}

.status {
  padding: 10px;
  cursor: pointer;
  //color: red;
}

.media-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 10;

  &.left-control {
    left: 10px;
  }

  &.right-control {
    right: 10px;
  }

  &:hover { // todo add hover check
    background-color: rgba(0, 0, 0, 0.8);
  }
}
</style>