<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "logotype",
  data() {
    return {
      imageSrc: require('@/assets/images/logo_header.jpg') // путь к картинке
    };
  }
}
</script>

<template>
  <div>
    <img :src="imageSrc" alt="Logotype" class="logo-image">
  </div>
</template>

<style scoped lang="scss">
.logo-image{
  width: 390px;
  height: 76px;
}
</style>