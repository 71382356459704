<script>
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "faq",

  created() {
    // Запрос к API для получения данных
    axios
        .get(`${process.env.VUE_APP_API_URL}faq/`)
        .then((response) => {
          this.questionsData = response.data; // ответ содержит массив вопросов
          this.isLoading = false; // Убираем состояние загрузки после получения данных
        })
        .catch(() => {
          console.log("Ошибка при загрузке данных");
          this.isLoading = false; // Убираем состояние загрузки даже в случае ошибки
        });
  },

  data() {
    return {
      questions: [
        // Пример данных
        {id: 1, text: 'Вопрос 1', answer: 'Ответ на вопрос 1'},
        {id: 2, text: 'Вопрос 2', answer: 'Ответ на вопрос 2'},
        {id: 3, text: 'Вопрос 3', answer: 'Ответ на вопрос 3'}
      ],

      activeQuestion: null, // индекс активного вопроса
      questionsData: [], // Здесь мы будем хранить загруженные данные о вопросах
      currentQuestionIndex: 0, // Индекс текущего вопроса
      isLoading: true, // Состояние загрузки
    }
  },
  methods: {
    toggleAnswer(index) {
      if (this.activeQuestion === index) {
        this.activeQuestion = null;
      } else {
        this.activeQuestion = index;
      }
    }
  }
}
</script>

<template>
  <div class="faq_container">
    <p class="faq_main_text"  @click="activeQuestion = null">
      FAQ
    </p>
    <div v-for="(question,index) in questionsData" :key="index" @click="toggleAnswer(index)" >
      <div :class="activeQuestion === index ? 'faq_item_main_active' : 'faq_item_main'">
        {{ question.question }}
      </div>
      <transition name="fade">
      <p v-if="activeQuestion === index" class="faq_item" v-html="question.answer">
      </p>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/base.scss";
.faq_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $dg2;
  align-items: center;
  margin-top: 3%;
  padding: 3%;
  width: 60%;
  height: auto;
  border-radius: 10px;
  transition: transform 1s ease;
  flex-wrap: wrap;
}
.faq_item_main{
  display: flex;
  flex-direction: column;
  color: $text_color;
  font-size: 20px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faq_item_main:hover { /*анимация при наведении */
  color: $text_color;
  text-shadow: 0 0 5px #6F7199, 0 0 10px white; /* Эффект свечения */
  border-bottom: 2px solid transparent; /* Прозрачная граница для эффекта */
  position: relative; /* Для позиционирования эффекта свечения */
}

.faq_item_main_active{
  font-size: 20px;
  text-align: center;
   color:white;
  text-shadow: 0 0 5px #f64e4e, 0 0 10px #ef4c4c; /* Эффект свечения */
  border-bottom: 2px solid transparent; /* Прозрачная граница для эффекта */
  position: relative; /* Для позиционирования эффекта свечения */
}

.faq_item{
  display: flex;
  flex-direction: column;
  color: $text_color;
  font-size: 15px;
  text-align: start;
  padding: 5px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.faq_main_text{
  font-size: 50px;
  color: $text_color;
  font-weight: bold;
  text-shadow: 0 0 5px #6F7199, 0 0 10px white; /* Эффект свечения */
  border-bottom: 2px solid transparent; /* Прозрачная граница для эффекта */
  position: relative; /* Для позиционирования эффекта свечения */
  margin-bottom: 3%;
}

.faq_main_text::after {
  content: ""; /* Создание псевдоэлемента */
  position: absolute; /* Абсолютное позиционирование */
  left: 0;
  right: 0;
  bottom: -5px; /* Расположение под элементом */
  height: 3px; /* Высота свечения */
  background-color: rgb(162, 191, 255, 0.8); /* Полупрозрачный белый фон */
  box-shadow: 0 0 10px #4f58d8, 0 0 30px #7f83cd; /* Эффект свечения */
  border-radius: 2px; /* Закругление углов для мягкости */
  transform: scaleX(1); /*чтобы изначально была полоска */
  transition: transform 0.3s;
}
.faq_main_text:hover::after { /*анимация при наведении */
  transform: scaleX(2);
}
.fade-enter-active {
  animation: bounce 0.5s;
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-10px);
  }
}

</style>