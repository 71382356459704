<script>
import Logotype from "@/components/svg/logotype.vue";
import home_icon from "@/components/svg/home_icon.vue";

export default {
  name: "header_top",
  components: {
    home_icon,
    Logotype,
  }
}
</script>

<template>
  <div class="header_top">
    <div class="container">

      <div class="header_top__brand">
        <div class="header_top__city_icon_container">
          <router-link :to="{name:'home'}">
            <home_icon></home_icon>
          </router-link>

          <a href="tel:+375297837777" class="header_top__main_text">
            <logotype></logotype>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/base";

.header_top {
  width: 100%;
  height: 70px;
  background-color: $dg2;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  .header_top {
    height: 90px;
  }
}

.header_top__brand {
  display: flex;
  flex-grow: 2;
  flex-wrap: wrap;
  justify-content: center;
}

.header_top__city_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  align-items: center;
  justify-content: space-between;
}

.header_top__brand_name {
  font-family: "Maitree", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 3px;
  color: $text_color;
}

.header_top__brand_name:nth-child(1) {
  margin-right: 10px;
}

.header_top__main_text {
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_top__main_info_item {
  align-items: center;
  padding: 5px 0;
  box-sizing: border-box;

  div:nth-child(1), div:nth-child(2) {
    margin-right: clamp(5px, 15px, 45px);
  }

}

.wrap_preventer {
  display: flex;
  justify-content: space-between;

}

.header_top__main_info {
  width: 50%;
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  flex-wrap: wrap;
}

.header_top__main_info_text {
  outline: none;
  text-decoration: none;
  font-size: 16px; // H3 analog value
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: $text_color;
  padding-left: 10px;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .header_top__main_info {
    width: unset;
  }
  .header_top__main_info_text {
    font-size: 60%;
    padding-left: 5px;
  }
}

.header_top__icon {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 767px) {
  .header_top__icon {
    width: 15px;
    height: 15px;
  }
}
</style>