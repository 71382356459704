<script>
import axios from "axios";
import Animated_fire from "@/components/svg/animated_fire.vue";

export default {
  name: "product_view",
  components: {Animated_fire},
  data() {
    return {
      currentMediaIndex: 0, // Индекс текущего медиа
      productData: undefined, // Данные о продукте
      isLoading: true, // Состояние загрузки
      status_mapping: {
        "available": "green",
        "temporary_unavailable": "orange",
        "unavailable": "red",
      },
      currentRouteName: this.$route.name, // Изначально синхронизировано

      isHandlingPopstate: false, // Флаг для предотвращения повторных вызовов
    };
  },
  created() {
    // Запрос к API для получения данных о продукте
    axios
        .get(`${process.env.VUE_APP_API_URL}products/${this.$route.params.id}/`)
        .then((response) => {
          this.productData = response.data;
          this.isLoading = false; // Убираем состояние загрузки после получения данных
        })
        .catch(() => {
          console.log("Ошибка при загрузке данных");
          this.isLoading = false; // Убираем состояние загрузки даже в случае ошибки
        });
  },
  computed: {
    // Получение текущего медиа для отображения
    currentMedia() {
      return this.productData.media_files[this.currentMediaIndex];
    },
  },

  methods: {
    // Переключение на следующее медиа
    nextMedia() {
      if (this.currentMediaIndex < this.productData.media_files.length - 1) {
        this.currentMediaIndex++;
      } else {
        this.currentMediaIndex = 0;
      }
    },
    // Переключение на предыдущее медиа
    prevMedia() {
      if (this.currentMediaIndex > 0) {
        this.currentMediaIndex--;
      } else {
        this.currentMediaIndex = this.productData.media_files.length - 1;
      }
    },
    goBackHandle(){
      this.$router.push({ name: 'catalog', params: { slug: 'boiler' } });
    },
  },
};
</script>

<template>
  <div class="container">

    <div v-if="!productData">
      <p>Загрузка...</p>
    </div>

    <div class="product-view">
      <!-- Показываем загрузочный индикатор, пока данные загружаются -->
      <div v-if="isLoading" class="loading-indicator">
        <div class="loader">
          <Animated_fire class="animated-fire"></Animated_fire>
        </div>
      </div>

      <!-- Основной контент рендерится только после того, как данные будут получены -->
      <div v-else>
        <!-- Левая колонка с медиа -->
        <div class="product-info-wrapper">
          <div class="media-area">
            <div class="media">
              <img v-if="currentMedia?.media_type === 'image'" :src="currentMedia?.file" alt="Product Image">
              <video v-else :src="currentMedia?.file" controls></video>
            </div>

            <!-- Контроллеры переключения медиа -->
            <button @click="prevMedia" class="media-control left-control">←</button>
            <button @click="nextMedia" class="media-control right-control">→</button>
          </div>

          <!-- Правая колонка с информацией о продукте -->
          <div class="product-info">
            <div class="product-data">
              <div class="product-initial">
                <!-- Контроллер выхода на предыдущую страничку -->
                <button @click="goBackHandle" class="product-initial goBack-control">←</button>
                <h1 class="product-title">{{ productData.name }}</h1>
              </div>
              <p v-html="productData.description" class="product-description"></p>
              <div :style="{'color': status_mapping[productData.status.value]}" class="product-status">
                {{ productData.status.label }}
              </div>
            </div>
            <div class="characteristics-table">
              <!-- Таблица характеристик -->
              <p class="characteristics-table-title">Характеристики</p>
              <table class="characteristics-table">

                <tbody>
                <tr v-for="(char, index) in productData.characteristics" :key="char.name"
                    :class="{'even-row': index % 2 === 0}">
                  <td class="char-name">{{ char.name }}</td>
                  <td class="char-value">{{ char.value }}</td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>


      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/base";

.product-view {
  width: 100%;
}

.product-info-wrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: 30px;
}

.media-area {
  flex: 1;
  position: relative;
  margin-top: 60px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 300px;
  overflow: hidden;

  .media {
    display: flex;
    /*justify-content: center;
    align-items: center;*/
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    img, video {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .media-control {
    position: absolute;
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    top: 12%;
    transform: translateY(40%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;

    &.left-control {
      left: 10px;
    }

    &.right-control {
      right: 10px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

.product-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  min-width: 300px;

  .product-initial {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.goBack-control {
      border: none;
      width: 24px;
      height: 24px;
      font-size: 30px;
      padding-top: 15px;
      cursor: pointer;
      margin-right: 30px;
      color: $dg2;
      background-color: transparent;
    }

    &.goBack-control:hover {
      color: $accent_color;
    }
  }


  .product-title {
    margin-top: 15px;
    font-size: 24px;
    font-weight: bold;
  }

  .product-description {
    margin-top: 10px;
    font-size: 16px;
    color: $dg2;
  }

  .product-status {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
  }
}

@media (screen and min-width: 800px) {
  .product-info {
    padding-left: 40px;

  }
}

.characteristics-table-title {
  margin-top: 20px;
  font-weight: 600;
  font-size: 18px
}

.characteristics-table {
  //flex: 1;
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  td {
    padding: 10px;
    border: 1px solid $lggrey;
  }

  .even-row {
    background-color: $extralightgrey_color; // Чередование цвета строк
  }
}

.loading-indicator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

</style>