<script>
export default {
  name: "email_icon"
}
</script>

<template>
  <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7" stroke="#000000" stroke-width="1.5"
          stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="3" y="5" width="18" height="14" rx="2" stroke-width="1.3" stroke-linecap="round"/>
  </svg>
</template>

<style scoped lang="scss">
@import "@/assets/base.scss";

path {
  stroke: $accent_color;
}
rect{
  stroke: $accent_color;
}

svg {
  height: 37px;
  width: 27px;
}
</style>