
<script>
import services from "@/components/home/services.vue";
import catalog_items from "@/components/home/catalog_items.vue";
import Faq from "@/components/home/faq.vue";

export default {
  name: "home_view",
  components: {
    Faq,
    catalog_items,
    services,
  }
}
</script>

<template>
  <services class="services"></services>
  <catalog_items class="catalog_items"></catalog_items>
  <div class="wrapper_faq">
    <faq></faq>
  </div>
</template>


<style scoped lang="scss">
.services {
  margin-top: 50px;
}

.catalog_items {
  margin-top: 60px;
}

.wrapper_faq {
  justify-content: center;
  display: flex;
  margin-bottom: 0;
  margin-top: 60px;
}
</style>
