<script>
export default {
  name: "catalog_items",
  data() {
    return {
      data: [
        {"title": "Бойлеры", "icon": require("@/assets/svg/gas_boiler.svg"), "link": "/catalog/boiler"},
        {"title": "Пакетные решения", "icon": require("@/assets/svg/packet_solution.svg"), "link": "/catalog/all-included"},
      ]
    }
  },
  methods:{
    goto(item){
      this.$router.push(item.link)
    }
  }
}
</script>

<template>
  <div class="container">
    <div v-for="(item, index) in data" class="catalog_item" :key="index" v-on:click="goto(item)">
      <img :src="item.icon" :alt="item.title">
      <p class="title">{{ item.title }}</p>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "@/assets/base.scss";

.container {
  gap: 15px;
}
.catalog_item {
  background-color: $dg2;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: $text_color;
  font-size: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;

  img {
    height: 100px;
  }

  .title {
    margin-top: 20px;
  }
}

@media (hover: hover) {
  /* when hover is supported */
  .catalog_item:hover{
    cursor: pointer;
  }
}
</style>