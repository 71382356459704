<script>
import axios from "axios"
import Product_card from "@/components/catalog/product_card.vue";
import Animated_fire from "@/components/svg/animated_fire.vue";


export default {
  name: "catalog_view",
  components: {Animated_fire, Product_card},

  data() {
    return {
      products: [],
      is_loading: true,
      fetch_error: false,
    }
  },

  created() {
    this.is_loading = true
    this.getFilteredProducts(this.$route.params.slug)
    this.is_loading = false
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    '$route.params.slug': function (newSlug, oldSlug) {
      this.is_loading = true
      this.getFilteredProducts(newSlug)
      this.is_loading = false
    }
  },
  methods: {
    async getFilteredProducts(slug) {
      let base_url = `${process.env.VUE_APP_API_URL}products/`
      if (slug !== undefined && slug !== "") {
        base_url = `${base_url}?category=${slug}`
      }
      await axios.get(base_url).then(response => {
        this.products = response.data
      }).catch(() => {
        this.fetch_error = true
      })
    },

    goto(productId){
      this.$router.push({ name: 'product', params: { id: productId } });
    },
  },
  computed: {
    title() {
      if (!this.$route.params.slug || this.products.length === 0) {
        return "Каталог"
      } else {
        return this.products[0].category.name
      }
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="loader" v-if="this.is_loading"><Animated_fire class="animated-fire"></Animated_fire></div>
    <div v-else>
      <div class="title">
        <p>{{ title }}</p>
      </div>

      <div class="products-wrapper">
        <div class="products" v-for="product in products" :key="product.id">
          <Product_card @click.prevent="goto(product.id)" :product_data="product"></Product_card>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  flex-direction: column;
}

.title {
  padding: 20px 0;
  font-size: 26px;
  font-weight: 600;
}

.products-wrapper {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 600px) {
  .products-wrapper {
    justify-content: center;
  }
}

.loader{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.animated-fire{
  transform: scale(150%);
}
</style>