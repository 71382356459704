import {createRouter, createWebHistory} from 'vue-router'
import home from "@/views/home.vue";
import catalog from "@/views/catalog.vue";
import product from "@/views/product.vue";

const routes = [
    {
        path: '/home',
        name: 'home',
        component: home,
        alias: '/'
    },
    {
        path: '/catalog/:slug?',
        name: 'catalog',
        component: catalog

    },
    {
        path: '/product/:id',
        name: 'product',
        component: product
    },

    { path: '/:catchAll(.*)', redirect: '/home' }, // Обработчик для несуществующих маршрутов
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
