<script>

import location_icon from "@/components/svg/location_icon.vue";
import email_icon from "@/components/svg/email_icon.vue";
import phone_icon from "@/components/svg/phone_icon.vue";
import telegram_icon from "@/components/svg/telegram_icon.vue";
import viber_icon from "@/components/svg/viber_icon.vue";
import whatsApp_icon from "@/components/svg/whatsApp_icon.vue";
import logotype_footer from "@/components/svg/logotype_footer.vue";

export default {
  name: "footer_component",
  components: {
    logotype_footer,
    whatsApp_icon, viber_icon, telegram_icon, phone_icon, email_icon, location_icon}
}
</script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__sitemap">
      </div>
      <div class="footer__logo_and_contacts">
        <div class="footer__lac_column column kotlov_logo">
          <a href="tel:+375297837777" class="wrapper">
            <logotype_footer></logotype_footer>
          </a>

        </div>
        <div class="footer__lac_column column contacts">
          <p class="footer__lac_title">
            Контакты
          </p>
          <div class="footer__lac_items">
            <div class="footer__lac_item">
              <phone_icon class="footer__icon"></phone_icon>

              <a class="footer__main_info_text" href="tel:+375297837777">
                <span itemprop="telephone">+375 (29) 783-77-77</span>
                <viber_icon class="footer__icon"></viber_icon>
                <whatsApp_icon class="footer__icon"></whatsApp_icon>
                <telegram_icon class="footer__icon"></telegram_icon>
              </a>

            </div>
            <div class="footer__lac_item">
              <email_icon class="footer__icon"></email_icon>
              <a itemprop="email" class="footer__main_info_text"
                 href="mailto:7797.by@gmail.com">7797.by@gmail.com</a>
            </div>
            <div class="footer__lac_item">
              <location_icon class="footer__icon"></location_icon>
              <address class="footer__main_info_text address" itemprop="geo" itemscope
                       itemtype="https://schema.org/GeoCoordinates">
                <span itemprop="streetAddress">г.Гродно ул. Красноармейская 69</span>
                <span itemprop="streetAddress">г. Минск, ул. Богдановича, 153Б</span>
                <meta itemprop="latitude" content="53.684850"/>
                <meta itemprop="longitude" content="23.848834"/>
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
@import "@/assets/base";

.footer {
  width: 100%;
  background-color: $dg2;
  color: $text_color;
  margin-top: 140px;
}

.container {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 50px 60px;
}

@media screen and (max-width: 1480px) {
  .container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.footer__sitemap, .footer__logo_and_contacts {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .footer__sitemap {
    flex-wrap: wrap;
    justify-content: center;
    flex-shrink: 1;
  }
}


.footer__sitemap {
  flex-grow: 3;
}

.footer__logo_and_contacts {
  flex-grow: 7;
}

@media screen and (max-width: 767px) {
  .footer__logo_and_contacts {
    flex-wrap: wrap-reverse;
    flex-shrink: 1;
  }
}

.column {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.footer__sitemap_column {
  box-sizing: border-box;
  padding-right: 20px;
}

.footer__sc_title, .footer__lac_title, .footer__sc_items, .footer__lac_items {
  font-size: 24px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
}

.footer__sc_items, .footer__lac_items {
  margin-top: 20px;
}

.footer__sc_items {
  margin-left: 20px;
}

.footer__sc_item:before {
  content: "\00B7";
  margin-right: 5px;
  font-weight: 900;
  text-decoration: none;
}


.contacts {
  box-sizing: border-box;
  padding-left: 60px;
  display: flex;
  flex-grow: 1;
}

@media screen and (max-width: 1480px) {
  .contacts {
    padding-left: 0;
    width: 50%;
  }
}

.footer__lac_items, .footer__lac_item {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .footer__lac_column, .footer__sitemap_column {
    min-width: 260px;
    margin-top: 40px;
  }
}

.footer__lac_item {
  display: flex;
  align-items: center;
}

.kotlov_logo {
  font-family: "Maitree", serif;
  font-size: 40px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  line-height: 113%;
  letter-spacing: 4px;

  .city {
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
      transform: scale(110%);
    }
  }
}

@media screen and (max-width: 1480px) {
  .kotlov_logo {
    width: 50%;
  }
}


.footer__icon {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.footer__main_info_text {
  outline: none;
  text-decoration: none;
  font-size: 24px;
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: $text_color;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__main_info_text.address{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.footer__sc_item {
  display: flex;
  padding: 0;
  margin-top: 5px;
}
</style>