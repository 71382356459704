<script>
export default {
  name: "home_icon"
}
</script>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="67" height="77" viewBox="0 0 30 30">
    <path fill="url(#paint0_linear_78_368)"
          d="m12 4.05l9.46 7.358a.75.75 0 1 1-.92 1.184l-.79-.614V20a.75.75 0 0 1-1.5 0v-9.189L12 5.951l-6.25 4.86V20a.75.75 0 0 1-1.5 0v-8.022l-.79.614a.75.75 0 0 1-.92-1.184l1.71-1.33V7.5c0-.69.56-1.25 1.25-1.25h1a1.25 1.25 0 0 1 1.242 1.111zM6.25 8.425V7.75h-.5V8.8z"/>
    <path fill="url(#paint0_linear_78_368)"
          d="M10.5 14.75a.25.25 0 0 0-.25.25v5a.75.75 0 1 1-1.5 0v-5c0-.966.784-1.75 1.75-1.75h3c.966 0 1.75.784 1.75 1.75v5a.75.75 0 0 1-1.5 0v-5a.25.25 0 0 0-.25-.25z"/>

    <defs>
      <linearGradient id="paint0_linear_78_368" x1="18.9738" y1="0" x2="18.9738" y2="35.7417"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#648ABBFA"/>
        <stop offset="1" stop-color="#bf2a22"/>
      </linearGradient>
      <linearGradient id="paint1_linear_78_368" x1="9.73187" y1="13.155" x2="13.2948" y2="32.7983"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#648ABBFA"/>
        <stop offset="1" stop-color="#bf2a22"/>
      </linearGradient>
      <linearGradient id="paint2_linear_78_368" x1="9.3662" y1="35.8471" x2="2.57923" y2="20.4572"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#bf2a22"/>
        <stop offset="1" stop-color="#648ABBFA"/>
      </linearGradient>
    </defs>

  </svg>
</template>

<style scoped lang="scss">

</style>